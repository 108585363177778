<section id="section_hero" class="section_hero">
  <div class="heading-wrapper">
    <h3 class="heading-6">TOURNAMENTS</h3>
    <div class="hero_heading_wrapper">
      <h5 data-w-id="2a9992e2-21dc-11a7-ceb5-4121862e87a7" style="opacity:0" class="colored-text">SEASON 5</h5>
      <h5 data-w-id="2a9992e2-21dc-11a7-ceb5-4121862e87a9" style="opacity:0" class="heading-5">TOURNAMENTS</h5>
    </div>
  </div>
</section>



<swiper class="section_tournaments_slider"            
            slideActiveClass="swiper-active"
            [spaceBetween]="32"            
            [centeredSlides]="true" 
            slidesPerView="auto"
            [navigation]="{
              prevEl: '.swiper-button-prev_',
              nextEl: '.swiper-button-next_'
            }">
            <ng-template swiperSlide *ngFor="let tournament of tournaments; index as i" >
              <div class="slider-item"  [class.tour_card_green]="i%2"  >
                <div class="tour_card_place">
                  <div class="tour_card_title" [class.color_black]="i%2">{{tournament.tournamentName}}</div>
                  <div class="tour_card_at" [class.color_black]="i%2">@ {{tournament.course[0].courseName}}</div>
                </div>
                <div class="tour_card_date_w">
                  <div class="tour_card_date_slide" [class.color_black]="i%2">
                    {{getMultiDateTour(tournament.tournamentDate | date: 'mediumDate')}}
                     
                    </div>
                </div>
              </div>
            </ng-template>
    </swiper>

    <div class="slider-navigation-wrapper">
      <img src="../../../assets/images/prev_g_arrow.svg" loading="lazy" width="69" alt="previous slide" class="swiper-button-prev swiper-button-prev_ slider-navigation prev-slide">
      <img src="../../../assets/images/next_g_arrow.svg" loading="lazy" width="69" alt="next arrow"     class="swiper-button-next swiper-button-next_ slider-navigation next-slide"></div>
      <div class="w-embed">
        <style>
    .text-outline-black {
    -webkit-text-stroke-width:1px;
    -webkit-text-stroke-color: black;
    }
    .text-outline-white {
    -webkit-text-stroke-width:1px;
    -webkit-text-stroke-color: white;
    }
    .text-outline-green {
    -webkit-text-stroke-width:1px;
    -webkit-text-stroke-color: #B2EB39;
    }
    </style>
      </div>

      <section class="section_tour_passed" *ngIf='hasTournaments'>
        <div data-w-id="08dcdc86-138c-f2fd-f3d1-389f87c08066" *ngFor='let tournament of tournaments' 
          class="season_item" 
          [routerLink]="['/tournamentDetails', tournament._id]"
          [class.isDisabled]="hasScores(tournament)">
          <div class="season_item_date">{{getDateTour(tournament.tournamentDate | date: 'mediumDate')}}</div>
          <div class="season_item_place_wrapper">
            <div class="season_item_place_xs">{{tournament.tournamentName}}</div>
            <div class="season_item_place_at">@ {{tournament.course[0].courseName}}</div>
          </div>
          <a class="square_cta w-inline-block">
            <div class="cta_bg"></div>
            <div class="text-block">RESULTS</div>
            <img src="../../../assets/images/arrow_45_alter.svg" loading="lazy" width="15" alt="arrow view more season details" class="arrow_45_alter">
            <img src="../../../assets/images/arrow_451.svg" loading="lazy" width="15" alt="arrow view more season details" class="arrow_45">
          </a>
          <div class="season_item_bg"></div>
        </div>
      </section>

      <section class="section_footer">
        <h4 class="footer-heading">Ready to hit<br><span class="colored-text">the Greens?</span></h4>
        <div class="center_content">
          <div class="space180"></div>
          <a href="/about#section_how_play" class="cta_primary white w-button">Join Us</a>
          <div class="space90"></div>
          <div class="container">
            <h3>contact</h3>
          </div>
        </div>
        <div class="big-email">TKCGOLFCLUB@GMAIL.COM</div>
        <div class="footer_bottom">
          <div>TKC golf club -  all rights reserved  2024</div>
        </div>
      </section>

      
<!--
<div class="row hidden hide"  *ngIf='hasTournaments'>
  <div class="col-12">
    <h1>Season 3 Tournaments</h1>
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            
            <th>Tournament</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let tournament of tournaments'>
             <td>
              <span>
                <button  class="btn btn-info btn-sm"  id="scores" (click)="gotToScores(tournament._id)">Scores</button>
              </span>
            </td>
              <td>
                  <a [class.isDisabled]="hasScores(tournament)" [routerLink]="['/tournamentDetails', tournament._id]"><b>
                    {{tournament.tournamentName}}</b> @ {{tournament.course[0].courseName}}</a>
              </td>
            <td>{{tournament.tournamentDate |date: 'shortDate'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div> -->
<div class="row" *ngIf='!hasTournaments'>
  <div class="spinner-border loader" role="status">
    <span class="sr-only loader">Loading...</span>
  </div>
</div>
